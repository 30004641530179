<template>
  <div
  id="main-wrapper"
    class="image-cover"
    style="
      overflow-y: hidden;
      overflow-x: hidden;
      background: url(/img/971TutorsImages/categories/es.jpeg);
      height: 100vh;
    "
  >
    <!-- <div class="main-step header-main-step">
      <img width="150" src="img/logo.png" class="logo" alt="" />
    </div> -->

    <div class="main-step header-main-step">
      <h3>Get Started</h3>
    </div>
    <div class="main-step">
      <!-- <h5 style="margin-top: 10px; margin-bottom: 10px">Step 1</h5> -->
      <button @click="m_asStudent" class="btn btn-theme main-asbtn">
        I'm a Student
      </button>
    </div>
    <div class="main-step">
      <button @click="m_asTutor" class="btn btn-theme main-asbtn">
        I'm a Tutor
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "cmp-loginpicker",
  async mounted() {
    setTimeout(() => {
      this.$localforage.setItem("fs_tempRole", null);
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
  methods: {
    m_route(p_name) {
      if (this.$route.name == p_name) return;
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: p_name });
    },
    async m_asStudent() {
      this.$localforage.setItem("fs_tempRole", "student");
      this.m_route("access");
    },
    async m_asTutor() {
      this.$localforage.setItem("fs_tempRole", "tutor");
      this.m_route("access");
    },
  },
};
</script>

<style scoped>
.main-asbtn {
  width: 230px;
  margin: auto;
}
.main-step {
  border: 1px solid #e9edf3;
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  margin: 0 auto;

  background: #fff;
  text-align: center;
  opacity: 0.95;
}
.header-main-step {
  margin-top: 25vh;
}

@media (max-width: 575px) {
  .header-main-step {
    margin-top: 15vh;
  }
}
</style>